import React from 'react';
import "./SupportServiceCancelUrl.scss";

const SupportServiceCancelUrl = () => {
	return (
		<div className="SupportServiceCancelUrl">
			Need help with billing or looking to cancel? {' '}
			<a href="https://secure.webmediaproz.com/signup/cancelplus.php" target="_blank" rel="noopener noreferrer">
				Click here.
			</a>
		</div>
	);
};



export default SupportServiceCancelUrl;