import React, { useState } from 'react';
import {
  useWidgetState
} from '@livechat/widget-react';
import { useTranslation } from 'react-i18next';
import useUserStateChange from '../../hook/useUserStateChange.js';
import SupportServiceCallNumbersOnly from "../SupportService/SupportServiceCallNumbersOnly.js";
import SupportServiceEmailAddressesOnly from "../SupportService/SupportServiceEmailAddressesOnly.js";
import SupportServiceCancelUrl from "../SupportService/SupportServiceCancelUrl.js";

import { RenderConditionalComponent, PROPERTY_ID } from'../RenderConditionalComponent/RenderConditionalComponent';


import CallIcon from '../../images/call.svg';
import EmailIcon from '../../images/email.svg';
import ChatIcon from '../../images/chat.svg';
import BillingIcon from '../../images/billing-support_icon.svg';
//import ChatActiveIcon from '../../images/chat-activated-blk.svg';

import { isWeb } from '../../services/util-service/util.service.js';
import { links } from '../../links.js';
import LiveChat from '../LiveChat/LiveChat.js';

import './SupportService.scss';

const ChatIconComponent = ({ onClick, children }) => {
  const widgetState = useWidgetState();
  const onChatClick = () => {
    if (typeof onClick === 'function') onClick();
    if (widgetState?.availability === 'online') {
      window.LiveChatWidget.call("maximize");
    }
  };
  return (
    <>
      <img
        src={ChatIcon}
        id="ChatBtnChange"
        className={["ChatIcon", widgetState?.availability === 'online' ? 'ChatIconOn' : ''].join(' ')}
        alt="chat"
        onClick={onChatClick}
      />
      <div className='MobileButton'>
        {children ? children : null}
      </div>
    </>
  );
};

const SupportService = () => {
  const { t } = useTranslation();
  const [logged] = useUserStateChange();
  const [mobileChatVisible, setMobileChatVisible] = useState(false);

  const toggleMobilestateVisible = () => {
    setMobileChatVisible(!mobileChatVisible);
  };

  const renderInfoElement = ({ icon, text, info, mobileView }) => {
    const isWebView = isWeb();
    return (
      <div className="InfoElement Column">
        <div className="Row AlignCenter">
          <div className="IconCircle">
            {isWebView ? icon : mobileView}
          </div>
          <div className="InfoName Column JustifyEnd">
            {text}
          </div>
        </div>
        {isWebView ? info : null}
      </div>
    );
  };

  const renderCallInfo = () => {
    const icon = (<img src={CallIcon} className="CallIcon" alt="call" />);
    const data = {
      icon,
      text: 'Call',
      mobileView: (
        <a
          href={`tel:${t('common:localSupportServiceCallNumber').replace(/\./g, '')}`}
          target={"_self"}
        >{icon}</a>
      ),
      info: (
        <div className="Column AlignEnd MainInfo">
          <div className="Column AlignEnd">
            <SupportServiceCallNumbersOnly />
          </div>
        </div>
      )
    };
    return renderInfoElement(data);
  };



  const renderChatInfo = () => {
    const data = {
      icon: (
        <ChatIconComponent />
      ),
      text: 'Chat',
      mobileView: (
        <ChatIconComponent onClick={toggleMobilestateVisible} children={<LiveChat />} />
      ),
      info: (
        <div className="Column AlignEnd MainInfo">
          <div className="Column AlignEnd">
            <div className="Designator">
              <LiveChat />
            </div>
            <div className="DesignatorValue">{t('SupportService.workingHours')} | {t('SupportService.mondayFriday')}</div>
            <div className="Row JustifyEnd">
              <div className="Column AlignEnd">
                <div className="Designator">{t('SupportService.saturday')}</div>
                <div className="DesignatorValue">{t('SupportService.closed')}</div>
              </div>
              <div className="Separator" />
              <div className="Column AlignEnd">
                <div className="Designator">{t('SupportService.sunday')}</div>
                <div className="DesignatorValue">{t('SupportService.closed')}</div>
              </div>
            </div>
          </div>
        </div>
      )
    };
    return renderInfoElement(data);
  };

  const renderMobileChatInfo = () => {
    const classes = ['MyAccountDashboardText', 'Column', 'AlignEnd', 'MainInfo'];
    if (logged) {
      classes.push('UserLoggedIn');
    }
    return mobileChatVisible ? (
      <div className={classes.join(' ')}>
        <div className="Column AlignEnd">
          <div className="DesignatorValue">{t('SupportService.workingHours')} | {t('SupportService.mondayFriday')}</div>
          <div className="DesignatorValue">{t('SupportService.saturday')} | {t('SupportService.closed')}</div>
          <div className="DesignatorValue">{t('SupportService.sunday')} | {t('SupportService.closed')}</div>
        </div>
      </div>
    ) : null;
  };

  const renderEmailInfo = () => {
    const icon = (<img src={EmailIcon} className="EmailIcon" alt="email" />);
    const data = {
      icon,
      text: 'Email',
      mobileView: (
        <a
          href={links.billingAndSupport}
          target={"_self"}
        >{icon}</a>
      ),
      info: (
        <div className="Column AlignEnd MainInfo Email">
          <div className="Column AlignEnd">
            <SupportServiceEmailAddressesOnly />
          </div>
        </div>
      )
    };
    return renderInfoElement(data);
  };


  const renderBillingInfo = () => {
    const icon = (<img src={BillingIcon} className="BillingIcon" alt="billing" />);
    const data = {
      icon,
      text: 'Billing',
      mobileView: (
          <a href="https://secure.webmediaproz.com/signup/cancelplus.php" target="_blank" rel="noopener noreferrer">{icon}</a>
      ),
      info: (
          <div className="Column AlignEnd MainInfo Email">
            <div className="Column AlignEnd">
              <SupportServiceCancelUrl />
            </div>
          </div>
      )
    };
    return renderInfoElement(data);
  };

  const classes = ['SupportService'];
  if (logged) {
    classes.push('UserLoggedIn');
  }
  if (mobileChatVisible) {
    classes.push('ChatInfoExpanded');
  }
  return (
    <>
      <div className={classes.join(' ')}>
        {renderCallInfo()}
        {renderChatInfo()}
        {renderEmailInfo()}

        <RenderConditionalComponent ids={[PROPERTY_ID.peterFever]}>
          {renderBillingInfo()}
        </RenderConditionalComponent>
      </div>
      {renderMobileChatInfo()}


    </>
  );
};

export default SupportService;